@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std"),
  url(./Assets/font/HelveticaNeueLTStd-Bd.otf) format("opentype");
}

@font-face {
  font-family: 'Helvetica Neue_light';
  src: local("Helvetica Neue_light"),
  url(./Assets/font/HelveticaNeueLTStd-Lt.otf) format("opentype");
}

:root {
  /* font start */
  --font-700: 'Helvetica Neue LT Std', sans-serif;
  --font-400: 'Helvetica Neue_light', sans-serif;
}
/* Apply the font to elements */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Apply the font-family to the elements */
body {
  font-family: 'Helvetica Neue LT Std', sans-serif;
  /* Use a fallback font family in case the specified font isn't available */
}

/* slick padding */
.slick-slide > div{
  margin: 0 10px;
}