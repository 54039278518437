.navbar3 {
    flex-direction: column;
    /* align-items: start; */
    padding-top: 0;
    max-width: 1280px;
    margin: auto;
    background: transparent;
    color: white;
    opacity: 0.99;
    z-index: 100;
    display: flex;

    align-items: center;
    display: flex;
    /* width: 100%; */
}
.container1 {
    max-width: 1280px;
    width: 90%;
    margin: auto;
    
}
.title_nav_bar {
    padding-bottom: 26px;
  }

.navbar-container3 {
    display: block;
    position: relative;
    height: 75px;
    max-width: 1280px;
    /* width: 100%; */
    padding: 0;
}
.navbar-container3-new{
    display: block;
    position: relative;
    height: 75px;
    /* width: 100%;
    max-width: 100%; */
    padding: 0;
}
.navbar-container3 input[type="checkbox"], .navbar-container3 .hamburger-lines {
    display: block;
}
.navbar-container3 .hamburger-lines .line {
    display: block;
    height: 3px;
    width: 75%;
    border-radius: 10px;
    background: white;
}
.navbar-container3 .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
}
.navbar3 input[type="checkbox"], .navbar3 .hamburger-lines {
    display: flex;
}
.navbar3 .menu-items {
    position: relative;
    padding-top: 7px;
    background: #fff;
    /* height: 100vh; */
    /* height: 27vh; */
    text-align: center;
    /* width: 100vw; */
    width: 100%;
    /* transform: translatex(-104%); */
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    /* box-shadow: 5px 0px 10px 0px #aaa; */
    /* overflow: scroll; */
    z-index: 1;
    gap: 25px;
    padding-left: 0;
}
.navbar3 .menu-items li {
    margin-bottom: -0.2rem;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: var(--font-300);
    text-decoration: none;
    color: #ef6f20;
    list-style: none;
    /* cursor: pointer; */
}

.navbar3 ul li a {
    /* display: block; */
    padding: 0.5rem;
    cursor: pointer;
}

.navbar-container3 input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
    background-color: #ef6f20;
}
.navbar-container3 .hamburger-lines {
    display: block;
    display: flex;
    flex-direction: column;
    height: 26px;
    justify-content: space-between;
    position: absolute;
    right: 0px;
    top: 25px;
    width: 47px;
    z-index: 2;
}



.logo3 {
    position: absolute;
    top: 0px;
    left: 0;
    width: 12%;
    height: 75px;
    object-fit: contain;
}
.navbar3-items{
    display: none;
    position: relative;
    /* height: 75px; */
    width: 100%;
    padding: 0;
}
.navbar3-items-open{
    height: 34vh;
    display: block;
}

  
.hide{
    display: none;
}
.navbar3 .menu-items1{

    transition: 0.5s;
    transition: opacity 0.3s ease-in-out;
 } 
@media screen and (max-width: 768px) {
    .navbar3 .menu-items {
        /* height: 42vh; */
    }
    .logo3 {
        position: absolute;
        top: 0px;
        left: 0px;
        /* width: 34%; */
        height: 75px;
        object-fit: contain;
        width: 137px;
    }
}    
@media (min-width: 768px) and (max-width:1024px){
    .logo3 {
        position: absolute;
        top: 0px;
        left: 0px;
        /* width: 34%; */
        height: 75px;
        object-fit: contain;
        width: 137px;
    }
}

