.home3-section8{
    width: 100%;
    margin-top: 5%;
}
.h3-sec8{
 max-width: 1280px;
 margin: auto;
 padding-left: 50px;
 padding-right: 50px;
}
.h3-sec8-top{
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-bottom: 14px;
}
.h3-sec8-top1{
width: 80%;
}
.h3-sec8-top2{
width: 20%;
margin-bottom: 2rem;
display: flex;
    justify-content: end;
    gap: 14px;
}
.h3-sec8-top1 p{
    color: #000;

font-family: var(--font-700);
font-size: 66px;
font-style: normal;
font-weight: 700;
line-height: 135.5%;
}
.h3-sec8-top2 button{
    background: transparent;
    border: none;
}
.h3-sec8-cards-div {
    display: flex;
    gap: 20px;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
}
.h3-sec8-card {
    width: calc(33.33% - 10px);
    /* width: 100%; */
    background:transparent;
    /* padding: 12px; */
}

.h3-sec8-card-title p {
    color: #000;
    margin-bottom: 3px;
    font-family: var(--font-700);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
    text-align: left;
    height: 67px;
}
.h3-sec8-card-des p {
    color: rgba(0, 0, 0, 0.70);
    text-align: center;
    font-family: var(--font-400);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    /* line-height: 0%; */
    line-height: 35px;
    text-align: justify;
}
.h3-sec8-card-img{}
.h3-sec8-card-img img {
    width: 100%;
    /* border-radius: 12px; */
}
.h3-sec8-card-title{
    margin-top: 15px;
}

.h3-sec8-cards-div::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome/Safari/Opera */
  } 

  @media screen and (max-width: 768px) {

  .h3-sec8-top1 p {
    color: #000;
    font-family: var(--font-700);
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
}   
.h3-sec8 {
    width: 90%;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
}
.h3-sec8-top {
    display: flex;
    flex-direction: column;
    align-items: normal;
    margin-bottom: 0;
}
.h3-sec8-top1 {
    width: 100%;
}
.h3-sec8-top2 {
    text-align: end;
    width: 100%;
}
  }
  @media (min-width: 1025px) and (max-width:1210px){
    .h3-sec8-card-des p {
        color: rgba(0, 0, 0, 0.70);
        text-align: center;
        font-family: var(--font-400);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
        /* line-height: 0%; */
        width: 93%px;
        line-height: 29px;
    }
  }
  @media (min-width: 2001px) and (max-width:2500px){
    .h3-sec8-card-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
        text-align: left;
    }
    .h3-sec8-card-des p {
        color: rgba(0, 0, 0, 0.70);
        text-align: center;
        font-family: var(--font-400);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
        /* line-height: 0%; */
        width: 93%px;
        line-height: 35px;
    }
  }

 
  @media (min-width: 768px) and (max-width:1024px){
  .h3-sec8-top1 p {
    color: #000;
    font-family: var(--font-700);
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
}
  }






  