.home3-footer{
    width: 100%;

    background-color: black;
    /* height: 434px; */
}
.h3-footer{
    max-width: 1280px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
}
.h3-footer-logo{
    text-align: center;
}
.foot3-6{
    width: 15%;
    padding-top: 39px;
    color: white;
}
.foot3-1 {
    width: 20%;
    color: white;
}
.footer3-input {
    background-color: transparent;
    border-bottom: 1px solid white ;
    border-top: none;
    border-left: none;
    border-right: none;
    
    height: 40px;
    /* padding-left: 18px; */
    color: rgba(255, 255, 255, 0.34);
    font-family: var(--font-400);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    width: 197px;
}
.foot3-1 button{
    background-color: #EF6F20;
    border: none;
    color: white;
    width: 119px;
    margin-top: 32px;
    height: 42px;
}
.foot3-4 {
    width: 20%;
    color: white;
}
.foot3-5 {
    width: 16%;
    padding-top: 39px;
    color: white;
}
.foot3-2 {
    width: 18%;
    color: white;
}
.foot3-3 {
    width: 12%;
    color: white;
    padding-top: 41px;
}
.footer3-copywrite{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    
}
.footer3-copywrite p{
    color: rgba(255, 255, 255, 0.28);

text-align: center;
font-family: var(--font-400);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 190.5%;
}
.h3-footer-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 187px;
    padding-top: 1rem;
}
.footer3-social {
    display: flex;
    gap: 20px;
    width: 100%;
    /* margin-top: 43px; */
}
.foot-p{
    color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 23px;
font-family: var(--font-400);
margin-top: 10px;
}
.footer-del{
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    justify-content: space-between;
}
.h3-footer-logo>img{
    width: 137px;
}
@media screen and (max-width: 768px) {
    .home3-footer {
 
        height: auto;
    }
    .foot3-4 {
        width: 100%;
    }
    .foot3-5 {
        width: 100%;
        padding-top: 5px;
    }
    .foot3-6 {
        width: 100%;
        padding-top: 5px;
        color: white;
    }
    .foot3-2 {
        width: 100%;
        color: white;
        padding-top: 29px;
    }
    .foot3-3 {
        width: 100%;
        color: white;
        padding-top: 5px;
    }
    .foot3-1 {
        width: 100%;
        color: white;
        padding-top: 27px;
    }
    .footer3-copywrite {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: unset;
    }
    .footer3-social {
        display: flex;
        gap: 20px;
        width: 100%;
        /* margin-top: 43px; */
        justify-content: center;
    }
    .footer-del {
        display: flex;
        width: 100%;
        margin: auto;
        margin-top: 15px;
        flex-direction: column;
    }
    .h3-footer {
        width: 90%;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }
}