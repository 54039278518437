.home3-section5{
    width: 100%;
    margin-top: 2%;
}
.h3-sec5-1{
    width: 58%;
    margin: 0 auto;
    display: flex;
    justify-content: start;

}
.h3-sec5-head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: auto; */
    max-width: 1280px;
    margin: auto;

    padding-left: 50px;
    padding-right: 50px;
}
.h3-sec5-head-img{
    width: 241px;
}
.h3-sec5-title p{
    color: #000;
    font-family:var(--font-700);
    font-size: 66px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
  
}
.h3-sec5-des{
    margin-left: 20px;
    /* width: 591px; */
    width: 94%;
}
.h3-sec5-des p{
    color: rgba(0, 0, 0, 0.70);

font-family:var(--font-400);
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 35px;
}
.h3-sec5-img{
    display: flex;
    /* text-align: revert-layer; */
    justify-content: end;
    width: 30%;
}
.h3-sec5-flex{
    display: flex;
    flex-direction: row;

}
.h3-sec5-2{
    width: 48%;
    margin: auto;
    margin-top: 49px;
    display: flex;
    justify-content: end;
}
.h3-sec5-2 img{
    /* width: 470px; */
    /* width: 97%; */
    /* margin-left: -89px; */

    width: 83%;
    height: 83%;
}

.h3-sec5-title{
    position: absolute;
    top: 29px;
    left: 39px;
}
.circle-text-div{
    position: relative;
    height: 296px;
}
@media screen and (max-width: 768px) {
.h3-sec5-1 {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.h3-sec5-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
}
.h3-sec5-head img {
    /* width: 112px; */
    width: 130px;
}
.h3-sec5-title p {
    color: #000;
    font-family: var(--font-700);
    /* font-size: 30px; */
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 135.5%; */
    line-height: 129.5%;
}
.h3-sec5-des {
    margin-left: 0px;
    width: 100%;
    text-align: justify;
}
.h3-sec5-img {
    display: flex;
    /* text-align: revert-layer; */
    justify-content: center;
    width: 100%;
}
.h3-sec5-img img{
    width: 50%;
}
.h3-sec5-flex {
    display: flex;
    flex-direction: column;
    margin-top: 17px;
}
.h3-sec5-1 {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.home3-section5 {

    margin-top: 30%;
}
.h3-sec5-2 img{
    width: 260px;
    margin-left: 0;
}
.h3-sec5-2 {
    width: 100%;
    margin: auto;
    margin-top: 49px;
    text-align: center;
    justify-content: center;
}
.h3-sec5-des p {
    margin-bottom: 0;
}
.circle-text-div {
    position: relative;
    height: 162px;
}
.h3-sec5-title {
    left: 23px;
    top: 28px;
}
}


@media (min-width: 768px) and (max-width:1024px){
    .h3-sec5-1 {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    .h3-sec5-head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec5-head img {
        width: 136px;
    }
    .h3-sec5-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 37px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;

    }
    .h3-sec5-des {
        margin-left: 0px;
        width: 100%;
        text-align: justify;
    }
    .h3-sec5-img {
        display: flex;
        /* text-align: revert-layer; */
        justify-content: center;
        width: 100%;
    }
    .h3-sec5-img img{
        width: 50%;
    }
    .h3-sec5-flex {
        display: flex;
        flex-direction: column;
    }
    .h3-sec5-1 {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    .home3-section5 {
    
        margin-top: 10%;
    }
    .h3-sec5-2 img{
        width: 260px;
        margin-left: 0;
    }
    .h3-sec5-2 {
        width: 100%;
        margin: auto;
        margin-top: 49px;
        text-align: center;
        justify-content: center;
    }
    .h3-sec5-des p {
        margin-bottom: 0;
    }

    .h3-see-client {
        padding-left: 0px;
        display: flex;
        gap: 41px;
        align-items: start;
    }
    .h3-sec5-title {
        position: absolute;
        top: 23px;
        left: 20px;
    }
    .circle-text-div {
        position: relative;
        height: 162px;
    }
}
/* @media (min-width: 1025px) and (max-width:1210px){
    .h3-sec5-head-img {
        width: 200px;
    }
    .h3-sec5-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: 129.5%;
        margin-top: -166px;
        margin-left: 44px;
    }
   
    .h3-sec5-des p {
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-400);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
    }
   
    .h3-see-client p {
        color: #EF6F20;
        font-family: var(--font-700);
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }

}

@media (min-width: 3501px) and (max-width:3600px){

    .h3-sec5-head-img {
        width: 503px;
    }
    .h3-sec5-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 121px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
        margin-top: -414px;
        margin-left: 141px;
    }
    .h3-sec5-des p {
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-400);
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 79px;
    }
} */