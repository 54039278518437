.home3-section7{
    width: 100%;
    margin-top: 9%;
    /* background-color: black; */
    /* height: 500px; */
}
.h3-blk-div{
    background-color: black;
    width: 100%;
    /* height: 467px; */
    height: 510px;
}
.h3-sec7-hr{
    padding-top: 96px;
    width: 92%;
}
.h3-sec7-hr hr{
    height: 2px;
    border: none;
    background-color: white;
    opacity: 1;
}
.h3-sec7{
    max-width: 1280px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
    /* margin-top: 40px; */
    margin-top: 54px;
}
.h3-sec7-clients p{
    color: #FFF;

    font-family: var(--font-700);
    font-size: 66px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
}
.h3-sec7-flex{
    display: flex;
}
.h3-sec7-clients{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.h3-sec7-logos{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 27px;
}
.h3-sec7-logos1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.h3-sec7-logo-img{
    /* filter: invert(1); */
}
.h3-sec7-logos2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.h3-sec7-logo-div{
    /* width: calc(33.33% - 10px);
    justify-content: center;
    display: flex; */
    text-align: center;
}

    .h3-sec7-part2 {
        /* margin-top: -202px; */
        margin-top: -23px;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

.h3-sec7-card{
    display: flex;
    flex-direction: row !important;
    width: 100%;
    background:transparent;
}


.h3-sec7-card-top{
    display: flex;
    flex-direction: row;
    margin-top: 19px;
}
.h3-sec7-top2 button{
    background: transparent;
    border: none;
    display: none;
}
.h3-sec7-card-img{
    width: 457px;
    height: 475px;
    /* width: 492px; */
    /* height: 475px; */
}
.h3-sec7-card-content{
    width: 55%;
    /* flex: 1; */
}
.h3-sec7-top1{
width: 20%;
}
.h3-sec7-top2{
width: 100%;
display: flex;
    justify-content: end;
    /* margin-top: 141px; */
    margin-top: 0;
    z-index: 111;
    gap: 19px;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}
.h3-sec7-top1 p{
    color: rgba(255, 255, 255, 0.20);

font-family:var(--font-700);
font-size: 144px;
    font-style: normal;
    font-weight: 700;
    line-height: 77px;
}
.h3-sec7-des{
    color: rgba(0, 0, 0, 0.70);

font-family: var(--font-700);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 194.5%;
}
.h3-sec7-slider{
    width: 100%;
    margin-top: -86px;
    padding-left: 50px;
    padding-right: 50px;
}

.h3-sec7-cards-div {
    /* display: flex; */
    /* gap: 20px; */
    /* overflow-x: auto; */
    /* scroll-snap-type: x mandatory; */
    flex-direction: row;
    width: 100%;
  }
  .h3-sec7-cards-div::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome/Safari/Opera */
  } 
  .h3-sec7-card-btm{
    display: flex;
  }
  .orange-txt{
    color: #EF6F20;
  }

  .h3-sec7pp{
    color: rgba(0, 0, 0, 0.05);;

    font-family:var(--font-700);
    font-size: 144px;
        font-style: normal;
        font-weight: 700;
        line-height: 77px;
        width: 20%;
    text-align: end;
  }
  .h3-sec7pp1{
    width: 80%;
    font-size: 18px;
    font-family: var(--font-700);
    font-weight: 400;
  }
  .h3-sec7-1{
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .h3-sec7-card-img img {
    width: 100%;
    /* border-radius: 12px; */
}
.h3-sec7-card-content{

}
.h3-lg1{

    width: 61%;
    /* padding-top: 19px; */
}
.h3-lg2{

    width: 56%
}
.h3-lg3{
    /* filter: brightness(5.1); */
    width: 68%;
    /* margin-top: 21px; */
}
.h3-lg4{
    width: 53%;
}
.h3-lg5{
    filter: brightness(10.1);
    width: 107px;
}
.h3-lg6{
    width: 53%;
    /* filter: brightness(10.1); */
}
.h3-sec7-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .h3-sec7-logo-div {
    width: 29.33%; /* Set width for 3 logos per row, considering margins */
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
  .h3-sec7-logo-div>img{
   width: 82%;
    object-fit: contain;
  }
  .h3-sec7{
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }
  




  @media screen and (max-width: 768px) {
    .h3-sec7-clients {
        width: 100%;
  
    }
  .h3-sec7-flex {
    display: flex;
    flex-direction: column;
  }
  .h3-sec7-logos {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    gap: 35px;
    margin-top: 23px;
    padding: 0 1rem;
}
.h3-sec7-slider {
    width: 90%;
    margin: auto;
    /* margin-top: 28px; */
    margin-top: 100px;
    padding-left: 0;
    padding-right: 0;
}
.h3-blk-div {
    background-color: black;
    width: 100%;
    /* height: 670px; */
    height: 690px;
}
.h3-sec7-logos1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 35px;
}
.h3-sec7-logos2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 35px;
}
.h3-sec7-clients p {
    color: #FFF;
    font-family: var(--font-700);
    font-size: 34px;
  
}
.h3-sec7-des{
    width: 100%;
}
.h3-sec7-card-content {
    width: 100%;
    text-align: justify;
}
.h3-sec7-card-img {
    width: 100%;
    height: auto;
}
.h3-sec7-1 {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.h3-sec7-top1 p {
    color: rgba(0, 0, 0, 0.05);
    font-family: var(--font-700);
    font-size: 106px;
    font-style: normal;
    font-weight: 700;
    line-height: 77px;
    width: 50%;
    /* text-align: end; */
    margin-bottom: 0;
}
.h3-sec7pp {
    font-size: 106px;
}
.h3-sec7-card-top {
    margin-top: 0;
    height: 50px;
}
.h3-sec7-part2 {
    margin-top: -202px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.h3-sec7-top2 {
    width: 90%;
    display: flex;
    justify-content: end;
    margin-top: 86px;
    z-index: 111;
    position: relative;
    padding-left: 0;
    padding-right: 0;
}
.h3-sec7-hr {
    padding-top: 55px;
    width: 92%;
    /* padding-bottom: 28px; */
    padding-bottom: 0;
}
.h3-sec7-logo-div>img {
    width: 100%;
    object-fit: contain;
}
.h3-sec7-logos {

    /* justify-content: space-around; */

  }
}

  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .h3-sec7-logo-div {
      width: 135px; /* Set width for 2 logos per row on smaller screens */
      display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    
    }
  }
  @media (min-width: 769px) and (max-width:1024px){
    .h3-sec7-clients p {
        font-size:37px;
    }
    .h3-blk-div {
        background-color: black;
        width: 100%;
        /* height: 420px; */
        height: 450px;
    }
    .h3-sec7-clients {
        /* width: 30%; */
    }
    .h3-sec7-logos {
        /* width: 70%; */
    }    
    .h3-sec7-logo-div {
        width: 27%;
        /* margin-bottom: 20px; */
    }
    .h3-sec7-des {
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-700);
        font-size: 10px;
        margin-bottom: 0;
    }
    .h3-sec7-card-img {
        width: 363px;
        /* height: 475px; */
        height: auto;
    }
    .h3-sec7-hr {
        padding-top: 43px;
        width: 92%;
    }
    .h3-sec7-logo-div>img {
        width: 89%;
    }
    .h3-sec7-top1 p {
        font-size: 99px;
        margin-bottom: 0;
    }
    .h3-sec7pp {
        font-size: 99px;
    }
    .h3-sec7-card-top {
        margin-top: 21px;
    }
}

  @media (min-width: 1025px) and (max-width:1078px){
    .h3-sec7-logo-div {
        width: 28.33%;
        /* margin-bottom: 20px; */
    }
  }
  @media (min-width: 1025px) and (max-width:1210px){

    .h3-blk-div {
        background-color: black;
        width: 100%;
        /* height: 490px; */
        height: 503px;
    }
    .h3-sec7-des {
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-700);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 194.5%;
    }
  
  }

