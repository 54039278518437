.home3-section2{
    width: 100%;
    margin-top: 2%;
}
.h3-sec2-{
    width: 100%;
    position: relative;
}
.h3-sec2{
   max-width: 1280px;
    margin: auto;
    display: flex;
    /* gap:  10px; */
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
/*     
    padding-left: 4%;
    padding-right: 4%; */
}
.h3-sec2-head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    /* margin-top: 55px; */
    margin-top: 9%;
    border-top: 2px solid black;
}
.h3-sec2-head img{
    width: 266px;
}
.h3-sec2-title p{
    color: #000;
    font-family:var(--font-700);
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 139.5%;
    /* margin-top: -194px;
    margin-left: 20px; */
}
.h3-sec2-img img{
    width: 100%;
    object-fit: contain;
    /* overflow: hidden; */
    /* z-index: 11; */
}
.h3-sec2-des{
    margin-left: 20px;
    /* width: 545px; */
    width: 77%;
    margin-top: 4%;
}
.h3-sec2-des p{
    color: rgba(0, 0, 0, 0.70);

font-family:var(--font-400);
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 35px;
}
.h3-sec2-img{
    display: flex;
    /* text-align: revert-layer; */
    /* justify-content: end; */
    width: 30%;
    overflow: hidden;
    z-index: 11;
}

.h3-sec2-hr {
    /* width: 76%; */
    border: none;
    /* position: absolute; */
    /* top: 90px; */
}

.h3-sec2-circle-div{
    position: absolute;
}
.h3-sec2-title{
    position: absolute;
    top: 59px;
    left: 20px;
}
.cir-text-div{
    position: relative;
    /* height: 330px; */
    height: 300px;
}

.white-text {
    color: #FFF;
}
@media screen and (max-width: 457px){
    .cir-text-div {
        position: relative;
        height: 178px;
    }
}
@media (min-width: 456px) and (max-width:1024px){
    .cir-text-div {
        position: relative;
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .h3-sec2 {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec2-head {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        width: 100%;
        /* align-items: center; */
    }
    .h3-sec2-head img {
        width: 136px;
    }
    .h3-sec2-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
        /* margin-top: -101px; */
        /* margin-left: 20px; */
    }
    .h3-sec2-des {
        margin-left: 0px;
        width: 100%;
        text-align: justify;
        margin-top: 9%;
    }
    .h3-sec2-img {
        display: flex;
        /* text-align: revert-layer; */
        justify-content: center;
        width: 100%;
    }
    .h3-sec2-img img{
        width: 50%;
    }
    .h3-sec2-hr{
        width: 46%;
    border: 1px solid black;
    position: absolute;
    top: 41px;
    opacity: 1;
    }
    .home3-section2 {
        width: 100%;
       padding-top: 10%;
       margin-top: 0;
    }
    /* .cir-text-div {
        position: relative;
        height: auto;
    } */
    .h3-sec2-head {

        border-top: none;
    }
    .h3-sec2-title {
   
        top: 39px;
        left: 17px;
    }
    }
    

@media (min-width: 768px) and (max-width:1024px){
    .h3-sec2 {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec2-head {
        display: flex;
        flex-direction: column;
  
        width: 100%;
    }
    .h3-sec2-head img {
        width: 164px;
    }
    .h3-sec2-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 37px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
        /* margin-top: -101px; */
        /* margin-left: 20px; */
    }
    .h3-sec2-des {
        margin-left: 0px;
        width: 100%;
        text-align: justify;
        margin-top: 5%;
    }
    .h3-sec2-img {
        display: flex;
        /* text-align: revert-layer; */
        justify-content: center;
        width: 70%;
        align-items: center;
        text-align: center;
        object-fit: contain;
        margin: auto;
    }
    .h3-sec2-img img{
        width: 50%;
    }
    .h3-sec2-hr{
        width: 58%;
    border: 1px solid black;
    position: absolute;
    top: 41px;
    opacity: 1;
    }
    .home3-section2 {
        width: 100%;
        margin-top: 10%;
    }
    
    .h3-sec2-head {

        border-top: none;
    }
    .h3-sec2-title {
        left: 19px;
        top: 43px;
    }

}

@media (min-width: 1025px) and (max-width:1183px){
    .h3-sec2-title p {
        font-size: 49px;
        line-height: 133.5%;
    }
    .h3-sec2-head img {
        width: 208px;
    }
    .h3-sec2-title {
        top: 52px;
        left: 14px;
    }
    
.cir-text-div {
    height: 251px;
}
}
@media (min-width: 1183px) and (max-width:1270px){
    .h3-sec2-title p {
        font-size: 57px;
        line-height: 139.5%;
    }
    .h3-sec2-head img {
        width: 246px;
    }
    .cir-text-div {
        position: relative;
        height: 300px;
    }
    .h3-sec2-title {
        position: absolute;
        top: 60px;
        left: 21px;
    }
    
    
}
@media (min-width: px) and (max-width:1274px){
    .h3-sec2-title p {
        font-size: 61px;
        line-height: 133.5%;
    }
    .h3-sec2-head img {
        width: 220px;
    }
}
/* 
@media (min-width: 3500px) and (max-width:3600px){
  
}
/* 
@media (min-width: 1025px) and (max-width:1210px){
    .h3-sec2-head img {
    width: 194px;
}
.h3-sec2-title p {
    color: #000;
    font-family: var(--font-700);
    font-size: 51px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
}
.cir-text-div {
    position: relative;
    height: 231px;
}
}

@media (min-width: 1450px) and (max-width:2000px){
    .h3-sec2-head {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        width: 70%;
       
        margin-top: 14%;
    }

}
@media (min-width: 2001px) and (max-width:2500px){
    .h3-sec2-head {
        display: flex;
        flex-direction: column;
        justify-content: unset;
      
        margin-top: 14%;
    }
    .h3-sec2-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 84px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
    .h3-sec2-head img {
        width: 307px;
    }
    .h3-sec2-des {
        margin-left: 20px;
     
        width: 91%;
        margin-top: 13%;
    }
    .h3-sec2-des p {
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-400);
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 57px;
    }
}
@media (min-width: 2501px) and (max-width:3000px){
    .h3-sec2-head {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        width: 70%;
    
        margin-top: 14%;
    }
    .h3-sec2-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 121px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
    .h3-sec2-head img {
        width: 430px;
    }
    .h3-sec2-des {
        margin-left: 20px;

        width: 91%;
        margin-top: 16%;
    }
    .h3-sec2-des p {
        font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 83px;
    }
}

@media (min-width: 3001px) and (max-width:3500px){
    .h3-sec2-head {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        width: 70%;
   
        margin-top: 14%;
    }
    .h3-sec2-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 121px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
    .h3-sec2-head img {
        width: 430px;
    }
    .h3-sec2-des {
        margin-left: 20px;
      
        width: 91%;
        margin-top: 16%;
    }
    .h3-sec2-des p {
        font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 83px;
    }
}

@media (min-width: 3501px) and (max-width:3600px){
    .h3-sec2-head img {
        width: 548px;
    }
    .h3-sec2-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 156px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
    .h3-sec2-title {
        position: absolute;
        top: 142px;
        left: 91px;
    }
    .h3-sec2-head {

        margin-top: 6%;
        justify-content: unset;
    }

    .h3-sec2-title p {
        color: #000;
        font-family: var(--font-700);
        font-size: 121px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
 
    .h3-sec2-des {
        margin-left: 20px;
        width: 91%;
        margin-top: 16%;
    }
    .h3-sec2-des p {
        font-size: 59px;
    font-style: normal;
    font-weight: 600;
    line-height: 107px;
    }

} */