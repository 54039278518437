.home3-section6{
width: 100%;

}
.h3-sec6{
    max-width: 1280px;
    margin: auto;
}
.h3-sec6-part1{
    padding-left: 50px;
    margin-top: -233px;
}
.h3-sec6-head p{
    color: #000;
font-family: var(--font-700);
font-size: 66px;
font-style: normal;
font-weight: 700;
line-height: 135.5%;
}
.h3-sec6-del p{
/* width:608px; */
color: rgba(0, 0, 0, 0.70);

font-family:var(--font-400);
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 35px;
}
.h3-see-client{
    /* padding-left: 50px; */
    display: flex;
    gap: 41px;
    align-items: start;
}
.h3-see-client p{
    color: #EF6F20;

font-family: var(--font-700);
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 135.5%;
}
.h3-sec6-del-div {
    /* height: 114px; */
}
.h3-sec6-content {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    height: 115px;
}
.h3-sec6-content1 {
    width: 20%;
    color: rgba(0, 0, 0, 0.70);
    font-family: var(--font-400);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 194.5%;
    letter-spacing: 2.21px;
    text-transform: uppercase;
}
.h3-sec6-content2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.h3-sec6-content3 {
    width: 5%;
    display: flex;
    align-items: baseline;
    text-align: end;
    justify-content: end;
}
.h3-sec6-content11{
    height: 115px;
    display: flex;
    flex-direction: row;
}
.h3-sec6-content33{
    align-items: baseline;
}
.h3-sec6-button{
    color: #FFF;

text-align: center;
font-family: var(--font-400);
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 194.5%; /* 31.12px */
text-transform: uppercase;
background-color: #000;
width: 304px;
height: 58px;
border: none;
}
.h3-sec6-button-div{
    text-align: end;
    margin-top: 38px;
    padding-left: 50px;
    padding-right: 50px;
}
.h3-sec6-content3 button{
    border: none;
    background: transparent;
}
.h3-see-client button{
    border: none;
    background: transparent;
}
.h3-sec3-details{

}
.h3-sec6-h1{
    width: 95%;
}



/* Class to hide the details */
.hide {
    display: none;
}

.h3-sec6-part2{
    padding-left: 50px;
    padding-right: 50px;
}

.h3-sec3-details {
    display: none;
    height: 0; /* Initially set height to 0 */
    overflow: hidden; /* Hide any content overflowing the set height */
    transition: height 0.3s ease; /* Add a smooth transition effect */
}

/* Class to show the details */
.show {
    display: flex;
    height: 227px;
    flex-direction: column;
    justify-content: center;
}

.h3-client-btn img{
    width: 32px;
}
.h3-sec6-content22{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}
.new-class1{
    display: flex;
    width: 100%;
}
.h3-sec6-del{
    width: 55%;
}


/* accordian start */
.home-question-sec2{
    margin-top: 40px;
}
.accordian-main{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-top: 1px solid black;
}
.accordian-main:last-child {
    border-bottom: 1px solid black;
  }
.title-btn-div{
    display: flex;
    width: 100%;


    padding-bottom: 20px;
    padding-top: 20px;
}
.title-btn-div .title1{
    width: 20%;
}
.title-btn-div .title2{
    width: 80%;
    color: #000;
    font-family: var(--font-700);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 0;
    display: flex;
}
.title-btn-div .title1 p{
    color: rgba(0, 0, 0, 0.70);
    font-family: var(--font-400);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 194.5%;
    letter-spacing: 2.21px;
    text-transform: uppercase;
}
.title2-1{
    width: 95%;
}
.title-btn-div .btns{
    width: 5%;
    height: 5%;
}
.btns button{
    border: none;
    background: transparent;
    border: none;
    background: none;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    -webkit-transition-duration: 0.3s;
    transition: transform 0.3s ease; 
    transition-duration: 0.3s;
    -webkit-transition-property:transform;
}

.accordian-del{
    /* padding-bottom: 35px; */
}
.accordian-del-p{
    color: rgba(0, 0, 0, 0.70);

    font-family: var(--font-400);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    /* text-decoration-line: underline; */
    width: 80%;
}
.data-p{
    width: 100%;
}
.btns button img{
    width: 32px;
}
.h3-sec6 hr{
    opacity: 1;
}
.opened{

    align-items: baseline;
}
.closed{
    align-items: center;
}
@media (min-width: 768px) and (max-width:1024px){
    .h3-sec3-p1 {
        color: #000;
        font-family: var(--font-700);
        font-size: 20px;
    }
    .h3-sec6-part2{
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec6-part1 {
        padding-left: 0;
        margin-top: 0;
    }
    .h3-sec6-head p {
        color: #000;
        font-family: var(--font-700);
        font-size: 34px;
    }
    .h3-sec6-del p {
        width: auto;
        text-align: justify;

    }
    .h3-see-client {
        padding-left: 0px;

    }
    .h3-see-client p {
        color: #EF6F20;
        font-family: var(--font-700);
        font-size: 19px;
    }
    .h3-sec6-content11 {
        height: 337px;
    }
    .h3-sec6-content1 {
        /* width: 100%; */
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-400);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 119.5%;
        letter-spacing: 2.21px;
        text-transform: uppercase;
    }
    .h3-sec6-content {
        display: flex;
        /* flex-direction: column; */
        /* align-items: center; */
        height: 120px;
        gap: 18px;
    }
    .h3-sec6-content11 {
        height: 380px;
        display: flex;
        /* flex-direction: column; */
        gap: 18px;
    }
    .h3-sec6-content3 {
        width: 10%;
        display: flex;
        align-items: baseline;
        text-align: end;
        justify-content: end;
    }
    .h3-sec6-button-div {
        text-align: center;
        margin-top: 38px;
        padding-right: 0;
        padding-left: 0;
    }
    .h3-sec6 {
        width: 90%;
    }

    .h3-sec6-content22 {
        /* width: 100%; */
        display: flex;
        flex-direction:column;
        /* justify-content: space-between; */
        align-items: baseline;
    }
    .show {
        display: flex;
        height: 261px;
        flex-direction: column;
        justify-content: center;
    }
    .h3-sec6-del {
        width: 100%;
    }
    .h3-sec6-part1 {
        padding-left: 0px;
        margin-top: 0px;
    }
    .h3-sec6-head p {
        color: #000;
        font-family: var(--font-700);
        font-size: 37px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
    .h3-sec6-del {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .home3-section6 {
        padding-top: 16px;
    }
    .h3-sec6-part2{
        padding-left: 0px;
        padding-right: 0px;
    }
    .h3-sec6-part1 {
        padding-left: 0;
        margin-top: 0;
    }
    .h3-sec6-head p {
        color: #000;
        font-family: var(--font-700);
        font-size: 34px;
    }
    .h3-sec6-del p {
        width: auto;
        text-align: justify;

    }
    .h3-see-client {
        padding-left: 0px;

    }
    .h3-see-client p {
        color: #EF6F20;
        font-family: var(--font-700);
        font-size: 19px;
    }
    .h3-sec6-content11 {
        height: 337px;
    }
    .h3-sec6-content1 {
        width: 100%;
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-400);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 119.5%;
        letter-spacing: 2.21px;
        text-transform: uppercase;
    }
    .h3-sec6-content {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        height: 120px;
        gap: 18px;
    }
    .h3-sec6-content11 {
        /* height: 380px; */
        /* height: auto; */
        height: 120px;
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
    .h3-sec6-content3 {
        width: 10%;
        display: flex;
        align-items: baseline;
        text-align: end;
        justify-content: end;
    }
    .h3-sec6-button-div {
        text-align: center;
        margin-top: 38px;
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec6 {
        width: 90%;
    }
    .h3-sec6-content2 {
        width: 100%;
  
    }
    .h3-sec6-content22 {
        width: 100%;
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: baseline;
    }
    .show {
        display: flex;
        height: 315px;
        flex-direction: column;
        justify-content: center;
    }
    .h3-sec6-del {
        width: 100%;
    }
    .title-btn-div {
        flex-direction: column;
    }
    .title-btn-div .title1 {
        width: 100%;
    }
    .title2-1 {
        font-size: 18px;
    }
    .title-btn-div .title2 {
        width: 100%;
    }
    .accordian-del-p{
        margin-bottom: 0;
    }
    .title2-1 {
        width: 90%;
    }
    .title-btn-div .btns {
        width: 10%;
    }
    .h3-client-btn img{
        width: 23px;
    }
}
 @media (min-width: 1025px) and (max-width:1210px){
    .h3-sec6-head p {
        color: #000;
        font-family: var(--font-700);
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
    .h3-sec6-part1 {
        padding-left: 50px;
        margin-top: -151px;
    }
    .h3-sec6-del p {
      
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-400);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
    }
}

