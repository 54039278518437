.home3-section4{
    width: 100%;
    margin-top: 9%;
    /* height: 100vh; */
}
.h3-sec4{
    background-color: #F8F8F8;
    height: 476px;
}
.h3-sec4-head p{
color: #000;
text-align: center;
font-family: var(--font-700);
font-size: 66px;
font-style: normal;
font-weight: 700;
line-height: 135.5%;
}
.h3-sec4-head{
    padding-top: 44px;
}
.h3-sec4-img{
    /* width: 70%; */
    margin: auto;
    max-width: 1280px;
}
.h3-sec4-div{
    height: 942px;
}
.h3-sec4-img img{
    width: 100%;
}

@media screen and (max-width: 768px) {
    .home3-section4{
        width: 100%;
        margin-top:22%;
    }

.h3-sec4-head p {
    color: #000;
    text-align: center;
    font-family: var(--font-700);
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
}
.h3-sec4-img {
    width: 90%;

}
.h3-sec4 {
    background-color: #F8F8F8;
    height: 240px;

}
.h3-sec4-div {
    /* height: 484px; */
    height: auto;
}
}

@media (min-width: 768px) and (max-width:1024px){
    .h3-sec4-div {
        height: 576px;
    }
    .h3-sec4-head p {
        color: #000;
        text-align: center;
        font-family: var(--font-700);
        font-size: 37px;
        
}
}