.home3-section1{
    width: 100%;
    margin: auto;
    height: 100%;
    margin-top: -75px;
}
.h3-sec1-flex{
    /* display: flex;
    flex-direction: column; */
    height: 812px;
    margin-top: -77px;
}
.h3-sec1-part1{
    background-image: url("../../../Assets/images/img\ Hero.png");
    width: 100%;
    /* height: 71%; */
    height: 55vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.h3-sec1-part2{
    background: linear-gradient(180deg, #EF6F20 0%, #EF6F20 100%);
    width: 100%;
    height: 45vh;
    align-items: center;
    display: flex;
    gap: 28px;
}
.h3-sec1-content{
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    margin: auto;
    align-items: center;
    gap: 14px;
    /* padding-top: 56px;
    padding-bottom: 56px; */
    z-index: 11111;
    padding-left: 50px;
    padding-right: 50px;
}
.h3-sec1-1{
 /* width: 55%; */
 width: 71%;
}
.h3-sec1-1 p{
    color: #000;

font-family:var(--font-700)!important;
font-size: 72px;
font-style: normal;
font-weight: 700;
line-height: 135.5%;
}
.h3-sec1-2{
    width: 29%;
}
.h3-sec1-2 p{
    color: #000;
    font-family: var(--font-400);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
}
.h3-sec1-2 button{
    color: white;
    background-color: #000;
    border: none;
    width: 185px;
    height: 56px;
    margin-top: 11px;
}


.menu-items li {
    list-style: none;
    color: #ef6f20;
    /* margin-left: 2.5rem; */
    font-size: 1rem;
    font-family: var(--font-300);
    /* width: 88px; */
}

.navbar-container3 {
    /* display: flex; */
    justify-content: space-between;
    /* height: 100vh; */
    align-items: center;
    /* max-width: 1280px; */
}

.navbar-container3 input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 68px;
    top: 20px;
    right: 0px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.navbar3 a {
    color: #000;
    text-decoration: none;
    /* font-weight: 500; */
    transition: color 0.3s ease-in-out;
    font-family: var(--font-500);
}



.navbar-container3 input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
}
.navbar-container3 input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
    background-color: #ef6f20;
}
.navbar-container3 .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
}
.navbar-container3 input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
    background-color: #ef6f20;
}
.navbar-container3 .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
}

.navbar-container3 .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
}

.navbar-container3 .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
}


.navbar-container3 input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
}

.navbar-container3 input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
}

.navbar-container3 input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
}

  .navbar-container3 input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
    background-color: #ef6f20; /* Change to the desired color */
  }
  /* .nav-hr{
    border: 1px solid white;
    margin-top: -724px;
    width: 100%;
  } */


  @media (min-width: 768px) and (max-width:1024px){
    .h3-sec1-content {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec1-part1 {
        /* height: 50vh; */
    }
    .h3-sec1-part2 {
        height: unset;
        padding-top: 2.2222222222rem;
        padding-bottom: 3.3333333333rem;
    }
    .h3-sec1-1 {
        width: 100%;
    }
    .h3-sec1-1 p {
    
        font-size: 37px;
        margin-bottom: 0;
    }
    .h3-sec1-2 {
        width: 100%;
    }

    .nav-hr {
        border: 1px solid white;
        margin-top: -775px;
        width: 100%;
    }
    .h3-sec1-2 p {
        text-align: justify;
        margin-bottom:27px;

    } 
  }



@media screen and (max-width: 768px) {

    .h3-sec1-content {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec1-part1 {
        /* background-image: url(http://localhost:3000/varna/amify/static/media/img%20Hero.a530f7a….png); */
    /* width: 100vw;
   
    
    object-fit: cover;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%; */
    height: 42vh;
    }
    .h3-sec1-part2 {
        height: unset;
        padding-top: 2.2222222222rem;
        padding-bottom: 3.3333333333rem;
    }
    .h3-sec1-1 {
        width: 100%;
    }
    .h3-sec1-1 p {
    
        font-size: 34px;
        margin-bottom: 0;
    }
    .h3-sec1-2 {
        width: 100%;
    }
 
    .nav-hr {
        border: 1px solid white;
        margin-top: -775px;
        width: 100%;
    }
    .h3-sec1-2 p {
        text-align: justify;
        margin-bottom: 25px;

    }
}
@media (min-width: 1025px) and (max-width:1125px){
    .h3-sec1-1 p {
        color: #000;
        font-family: var(--font-700);
        font-size: 55px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
    .h3-sec1-2 p {
        color: #000;
        font-family: var(--font-400);
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }
}
@media (min-width: 1125px) and (max-width:1278px){
    .h3-sec1-1 p {
        color: #000;
        font-family: var(--font-700);
        font-size: 62px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
    }
}
/* @media (min-width: 1025px) and (max-width:1210px){
    .h3-sec1-1 p {
        color: #000;
        font-family: var(--font-700);
        font-size: 57px;
        font-style: normal;
        font-weight: 700;
        line-height: 120.5%;
    }
} */