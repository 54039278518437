.home3-section3{
    width: 100%;
    margin-top: 9%;
}
.h3-sec3{
    max-width: 1280px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
}
.h3-sec3-content{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* height: 147px; */
    height: 136px;
}
.h3-sec3-del-div{
    height: 147px;
}
.h3-sec3-del-div1{
    /* height: 176px; */
    height: 147px;
}
.h3-sec3-content1{
    width: 20%;
    color: rgba(0, 0, 0, 0.70);

font-family: var(--font-400);
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 194.5%; /* 25.285px */
letter-spacing: 2.21px;
text-transform: uppercase;

}
.h3-sec3-content2{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 0;
}
.h3-sec3-content22{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 0;
    /* height: 173px; */
    /* height: 147px; */
    height: 136px;
}
.new-class{
    display: flex;
}
.h3-sec3-h1{
    width: 95%;
}
.h3-sec3-content3{
    width: 5%;
    display: flex;
    align-items: baseline;
    text-align: end;
    justify-content: end;
    margin-top: 9px;
}
.h3-sec3-p1{
    color: #000;

font-family: var(--font-700);
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 33px;
margin-bottom: 0;
}
.h3-sec3-p2{
    color: rgba(0, 0, 0, 0.70);
    font-family: var(--font-400);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    width: 87%;
    margin-bottom: 0;
}
.h3-sec3-p3{
    width: 87%;
}
.h3-sec3-p4{
    /* width: 737px; */
}
.h3-sec3-content1 p{ 
    /* width: 138px; */
}
.h3-sec3-content3 button{
    border: none;
    background: transparent;
}
.h3-sec3-btn-img{
    width: 32px;
}
.h3-sec3 hr{
    opacity: 1;
}
@media screen and (max-width: 768px) {
    .h3-sec3-content1 p{ 
        width: auto;
    }
.h3-sec3-del-div {
    height: auto;
}
.h3-sec3-content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* height: 188px; */
    height: 200px;
    justify-content: center;
}
.h3-sec3-p3 {
    width: auto;
}
.h3-sec3-p4 {
    width: auto;
}
.h3-sec3-content1 {
    width: 100%;

}
.h3-sec3-content2 {
    width: 100%;
}
.h3-sec3-content3 {
    width: 10%;
}
.h3-sec3-p1 {
    color: #000;
    font-family: var(--font-700);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
}
.h3-sec3 {
    width: 90%;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
}
.h3-sec3-content22 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 0;
    height: 185px;
}
.h3-sec3-del-div1 {
    height: 224px;
}
.content1{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 170px;

    justify-content: center;
}
.h3-sec3-p2 {
    width: 100%;
    margin-top: 10px;
}
.h3-sec3-btn-img {
    width: 23px;
}
}
@media (min-width: 768px) and (max-width:1024px){

.h3-sec3 {
    max-width: 1280px;
    margin: auto;
    width: 90%;
    padding-left: 0;
    padding-right: 0;
}

}



/* @media (min-width: 3501px) and (max-width:3600px){
    .h3-sec3-content1 {
        font-size: 38px;
    }
    .h3-sec3-del-div {
        height: 330px;
    }
    .h3-sec3-content {
        display: flex;
        flex-direction: row;
   
        height: 330px;
    }
    .h3-sec3-del-div1 {
        height: 330px;
    }
    .h3-sec3-p1 {
        color: #000;
        font-family: var(--font-700);
        font-size: 57px;
        line-height: 115px;

    }
    .h3-sec3-p2 {
        color: rgba(0, 0, 0, 0.70);
        font-family: var(--font-400);
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 85px;
        width: 87%;
        margin-bottom: 0;
    }
    .h3-sec3-content22 {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 0;
        height: 313px;
    }
} */