.home3-section9{
    margin-top: 4%;
    width: 100%;
    background-color:#EF6F20 ;
    background-image: url("../../../Assets/images/contact-back.png");
    object-fit: contain;
    background-size: 100% 100%;
    display: flex;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
}
.h3-sec9{
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 41px;
    padding-bottom: 60px;
}
.h3-sec9-part1{
    width: 40%;
}
.h3-sec9-title p{
    color: #000;
font-family: var(--font-700);
font-size: 66px;
font-style: normal;
font-weight: 700;
line-height: 135.5%;
}
.h3-sec9-part2{
    width: 60%;
}
.h3-form-sec9 {
    display: flex;
    flex-wrap: wrap;
}
.h3-form-name9 {
    width: 93%;
    font-size: 14px;
    color: #FFF;
    font-style: normal;
    font-family: var(--font-400);
    font-weight: 400;
    line-height: 194.5%;
    height: 50px;
    /* padding-left: 18px; */
    margin: 7px 7px;
    border-bottom: 1px solid white;
    background: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    letter-spacing: 2.21px;
    outline: none;
}
.h3-form-cmpny9 {
    width: 93%;
    font-size: 14px;
    font-style: normal;
    font-family: var(--font-400);
    font-weight: 400;
    line-height: 194.5%;
    height: 50px;
    /* padding-left: 18px; */
    margin: 7px 7px;
    border-bottom: 1px solid white;
    background: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    letter-spacing: 2.21px;
    outline: none;
    color: #FFF;
}
.h3-form-phone9 {
    width: 97%;
    margin: 5px;
    font-size: 14px;
    font-style: normal;
    font-family: var(--font-400);
    font-weight: 400;
    line-height: 194.5%;
    height: 50px;
    /* padding-left: 18px; */
    border-bottom: 1px solid white;
    background: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    letter-spacing: 2.21px;
    outline: none;
    color: #FFF;
}
.h3-form-comment9 {
    width: 97%;
    margin: 5px;
    font-size: 14px;
    font-style: normal;
    font-family: var(--font-400);
    font-weight: 400;
    line-height: 194.5%;
    height: 212px;
    /* padding-left: 18px; */
    padding-top: 10px;
    resize: none;
    background: transparent;
   border: none;
   border-bottom: 1px solid white;
    letter-spacing: 2.21px;
    outline: none;
    color: #FFF;
}
.h3-form-btn9{
    margin-top: 20px;
    margin-left: 5px;
}
.h3-form-btn9 button{
    background-color: black;
    color: white;
    width: 176px;
    border: none;
    height: 67px;
}
.h3-form-cmpny9::placeholder {
    color: white; /* Change this to your desired color */
  }
  .h3-form-name9::placeholder {
    color: white; /* Change this to your desired color */
  }
  .h3-form-phone9::placeholder {
    color: white; /* Change this to your desired color */
  }
  .h3-form-comment9::placeholder {
    color: white; /* Change this to your desired color */
  }
  .h3-sec9-content{
    /* width: 343px; */
    width: 84%;
  }
  .h3-sec9-content p{
    color: #FFF;

font-family: var(--font-400);
font-size: 18px;
font-style: normal;
font-weight:500;
line-height: 194.5%;
  }
  .validation {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .error {
font-size: 12px;
    color: rgb(134 6 6);
    margin-left: 18px;
    /* text-align: center; */
    display: flex;
    /* justify-content: center; */
    align-items: end;
  }
  .error-cmnt{
    font-size: 12px;
    color: rgb(134 6 6);
    margin-left: 18px;
 
  }

  .c_form {
    display: flex;
    margin-bottom: 22px;
    text-align: start;
}
.validation {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.form_in {
    border: none;
    border-bottom: 1px solid;
    font-family: var(--font-400);
    font-size: 17px;
    margin-right: 21px;
    margin-top: 59px;
    padding-bottom: 13px;
    width: 93%;
}
.validation1 {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.val_1 {
    /* width: 50%; */
    /* margin-top: -16px; */
    font-size: 13px;
    color: rgb(134 6 6);
    font-family: var(--font-500);
    /* position: fixed; */
    margin-left: 6px;
  }

  .popup-container {
    text-align: center;
    margin-top: 20px;
  }
  
.popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s;
    border-radius: 7px;
    width: 347px;
  }
  
  .close-button-pop {
    position: absolute;
    top: 10px;
    right: 22px;
    font-size: 28px;
    cursor: pointer;
    color: #000;
  }
  
  .popup h2 {
    font-size: 24px;
    margin-bottom: 0px;
    color: #64c890;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup p {
    font-size: 16px;
    color: grey;
    font-weight: 700;
    text-align: left;
    padding-top: 12px;
    font-family: var(--font-400);
    margin-bottom: 0;
  }
  
  .popup hr {
    color: #000;
    font-weight: 700;
  }
  
  .popup button {
    background-color:#EF6F20;
    width: 196px;
    border-radius: 20px;
    height: 32px;
    border: none;
    color: white;
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: #ffffff82; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    backdrop-filter: blur(4px);
  }
  .popup-head{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .popup-head img{
    width: 20%;
  }
  
.input-data{
    position: relative;
}
  .input-data label{
    position: absolute;
    bottom: 21px;
    left: 9px;
    pointer-events: none;
    transition: all 0.3s ease;
    font-size: 14px;
    font-style: normal;
    font-family: var(--font-400);
    font-weight: 400;
    color: #FFF;
    letter-spacing: 2.21px;
  }
.input-data input:focus ~ label,
.input-data input:valid ~ label{
    transform: translateY(-20px);
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Optional: Remove default margin */
}


/* .input-data input:valid ~ label{
    transform: translateY(-20px);
} */
  @media screen and (max-width: 768px) {
    .home3-section9 {

        height: auto;
  
    }
    .h3-sec9 {
        width: 90%;
        /* margin: auto; */
        /* display: flex; */
        flex-direction: column;
        /* margin-top: 42px; */
        /* margin-bottom: 42px; */
        padding-left: 0;
        padding-right: 0;
    }
    .h3-sec9-part1 {
        width: 100%;
    }
    .h3-sec9-part2 {
        width: 100%;
    }
    .h3-form-name9{
        width: 96%;
    }
    .h3-form-cmpny9{
        width: 96%;
    }
    .h3-sec9-title p {
        color: #000;
        font-family: Helvetica Neue LT Std;
        font-size: 34px;
    }
    .h3-sec9-content {
        width: auto;
        text-align: justify;
    }
    .c_form {
        flex-direction: column;
    }
    .c_form2 {
        margin-bottom: 0;
    }
    .form_in, .form_txt, .validation {
        width: 100%;
    }
    .form-9{
        width: 100%;
    }
  }

   
  @media (min-width: 768px) and (max-width:1024px){
  .h3-sec9-title p {
    color: #000;
    font-family: Helvetica Neue LT Std;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
}
  }